import axios from '@/axios.js'

const state = () => ({
    roles: []
})

const mutations = {
    SET_ROLES (state, payload) {
      state.roles = payload
    },
    ADD_ROLE (state, item) {
      state.roles.unshift(item)
    },
    UPDATE_ROLE (state, role) {
      const roleIndex = state.roles.findIndex((p) => p.id === role.id)
      Object.assign(state.roles[roleIndex], role)
    },
    REMOVE_ROLE (state, itemId) {
      const ItemIndex = state.roles.findIndex((p) => p.id === itemId)
      state.roles.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchRole ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/role`)
        .then((response) => {
          commit('SET_ROLES', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addRole ({ commit }, role ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', role)
        .then((response) => {
          commit('ADD_ROLE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateRole ({ commit }, role) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${role.id}`, role)
        .then((response) => {
            commit('UPDATE_ROLE', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRole ({ commit }, role) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${role.id}`)
        .then((response) => {
            commit('REMOVE_ROLE', role.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}